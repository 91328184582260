.routine-group__list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
  padding: 0;
}

/* .routine-group__item:active, */
.routine-group__item-label--hold {
  border-radius: 8px;
  background: rgba(255, 0, 0, 0.2);
  outline: solid 4px rgba(255, 0, 0, 0.2);
}

.routine-group__item-label {
  display: flex;
  gap: 4px;
  user-select: none;
}

.routine-group__item-when-done {
  align-self: center;
  background-color: rgba(0, 128, 0, 0.6);
  border-radius: 4px;
  color: white;
  font-size: 80%;
  font-weight: 600;
  padding: 2px 4px;
  text-wrap: nowrap;
}
